import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { BrowserRouter as Router } from "react-router-dom";
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider
} from "@material-ui/core/styles";
import { Primary } from "themes";
import CookieBanner from "./components/CookieBanner";

// @ts-ignore
let theme = createMuiTheme(Primary);
theme = responsiveFontSizes(theme);

function App() {
  return (
    <div className="App">
      <Router>
        <ThemeProvider theme={theme}>
          <CookieBanner />
        </ThemeProvider>
      </Router>
    </div>
  );
}

export default App;
