const theme = {
  palette: {
    primary: {
      light: "#22b970",
      main: "#17af66",
      dark: "#0c7f47",
      contrastText: "#2c2c2c"
    },
    font: {
      light: "#e5e5e5",
      main: "#2c2c2c",
      dark: "#595959"
    },
    bg: {
      light: "#e5e5e5",
      main: "#f3f3f3",
      dark: "#dadada",
      ultralight: "#fafafa"
    },
    secondary: {
      light: "#3d656c",
      main: "#103b41",
      dark: "#00161b",
      contrastText: "#fff"
    },
    actions: {
      error: "#9B5555"
    }
  },
  typography: {
    fontFamily: ["Inter", "Helvetica Neue", "Roboto", "Arial", "sans-serif"]
  },
  overrides: {
    MuiButtonGroup: {
      contained: {
        boxShadow: "none"
      }
    }
  },
  props: {
    MuiButton: {
      disableElevation: true
    }
  }
};

export default theme;
